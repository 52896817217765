import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback
} from 'react'
import {
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Radio,
  InputAdornment,
  Table,
  Text,
  message
} from 'tea-component'
import { useForm, Controller } from 'react-hook-form'
import { defaultDepend, dependType } from './defaultNode'
const { scrollable, removeable } = Table.addons

let id = 0
const getId = () => id++

export default forwardRef(({ setData, onDelete }, ref) => {
  const [visible, setVisible] = useState(false)
  const { control, handleSubmit, formState, reset } = useForm({ mode: 'all' })
  const [title, setTitle] = useState('')
  const [sourceData, setSourceData] = useState(null)
  const [targetData, setTargetData] = useState(null)
  const [records, setRecords] = useState([])
  const [edgeInfo, setEdgeInfo] = useState(null)

  useImperativeHandle(ref, () => ({
    open: (info, source, target) => {
      reset({})
      setSourceData(source)
      setTargetData(target)
      const resetInfo = { ...defaultDepend, ...info }
      if (source.nodeType === 'Mock') {
        resetInfo.depend_table = source.name
        resetInfo.dataset_format = source.out_format
        resetInfo.col_split = source.col_split
        resetInfo.col_kv_split = source.col_kv_split
        if (source.id === target.id) {
          resetInfo.dependType = 'self_partition'
        } else {
          resetInfo.dependType = resetInfo.dependType || 'other_table'
        }
      } else if (source.nodeType === 'DataSet') {
        resetInfo.dependType = resetInfo.dependType || 'outer_dataset'
        resetInfo.dataset_format = source.dataset_format
        resetInfo.col_split = source.col_split
        resetInfo.dataset_url = source.dataset_url
        resetInfo.col_kv_split = source.col_kv_split
        if (source.dataset_format !== 'only_v') {
          source.columns = source.need_keys_in_dataset?.map(item => {
            return {
              col_name: item
            }
          })
        } else {
          source.columns = source.position_in_dataset?.map(item => {
            return {
              col_name: item
            }
          })
        }
      }
      const {
        table_cols,
        depend_table_cols,
        need_keys_in_dataset,
        position_in_dataset
      } = resetInfo
      const recordsList = []
      for (let index = 0; index < table_cols.length; index++) {
        let recordsItem = { id: getId(), table_cols: table_cols[index] }

        if (depend_table_cols[index] && source.nodeType === 'Mock') {
          recordsItem = {
            ...recordsItem,
            depend_table_cols: depend_table_cols[index]
          }
        } else if (
          need_keys_in_dataset[index] &&
          source.dataset_format !== 'only_v'
        ) {
          recordsItem = {
            ...recordsItem,
            depend_table_cols: need_keys_in_dataset[index]
          }
        } else if (position_in_dataset[index]) {
          recordsItem = {
            ...recordsItem,
            depend_table_cols: position_in_dataset[index]
          }
        }
        recordsList.push(recordsItem)
      }
      setRecords(recordsList)
      setTitle((source?.name || '') + ' -> ' + (target?.name || ''))
      setVisible(true)
      reset(resetInfo)
      setEdgeInfo(resetInfo)
    }
  }))

  const handleSave = useCallback(
    (record, index) => {
      setRecords(prevRecords => {
        const newRecords = [...prevRecords]
        newRecords.splice(index, 1, {
          ...prevRecords[index],
          ...record
        })
        return newRecords
      })
    },
    [setRecords]
  )
  async function onSubmit(values) {
    const table_cols = []
    const depend_table_cols = []
    if (!records || records.length === 0) {
      message.error({
        content: '请添加依赖字段'
      })
      return
    }
    records.forEach(record => {
      if (record.table_cols && record.depend_table_cols) {
        table_cols.push(record.table_cols)
        depend_table_cols.push(record.depend_table_cols)
      }
    })
    let dataset = { ...values, table_cols }
    if (sourceData?.nodeType === 'DataSet') {
      if (sourceData.dataset_format === 'only_v') {
        dataset = {
          ...dataset,
          position_in_dataset: depend_table_cols,
          need_keys_in_dataset: []
        }
      } else {
        dataset = {
          ...dataset,
          need_keys_in_dataset: depend_table_cols,
          position_in_dataset: []
        }
      }
    } else {
      dataset = { ...dataset, depend_table_cols }
    }
    if (table_cols.length === 0) {
      message.error({
        content: '请添加依赖字段'
      })
      return
    }

    setData(dataset)
    console.log('dataset', dataset)
    setVisible(false)
  }

  function getStatus(meta) {
    if (!meta.isDirty && !formState.isSubmitted) {
      return null
    }
    return meta.invalid ? 'error' : 'success'
  }

  return (
    <div ref={ref}>
      <Modal
        visible={visible}
        caption={title}
        onClose={() => setVisible(false)}
        className='edge-modal'
      >
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Form>
              <Controller
                name='dependType'
                control={control}
                render={({ field, fieldState }) => (
                  <Form.Item
                    label='依赖方式'
                    status={getStatus(fieldState)}
                    message={fieldState.error?.message}
                  >
                    {(function () {
                      if (
                        ['self_partition', 'outer_dataset'].includes(
                          field.value
                        )
                      ) {
                        return (
                          <Form.Text>
                            {' '}
                            {
                              dependType.find(
                                item => item.value === field.value
                              )?.text
                            }
                          </Form.Text>
                        )
                      } else {
                        return (
                          <Select
                            {...field}
                            appearance='button'
                            options={dependType
                              .filter(
                                item =>
                                  !['self_partition', 'outer_dataset'].includes(
                                    item.value
                                  )
                              )
                              .map(item => ({
                                value: item.value,
                                text: item.text
                              }))}
                          ></Select>
                        )
                      }
                    })()}
                  </Form.Item>
                )}
              />
              <Controller
                name='circle'
                control={control}
                render={({ field, fieldState }) => (
                  <Form.Item
                    label='数据方式'
                    status={getStatus(fieldState)}
                    message={fieldState.error?.message}
                  >
                    <Radio.Group
                      value={field.value ? '1' : ''}
                      onChange={e => field.onChange(e ? true : false)}
                    >
                      <Radio name='1'>顺序循环</Radio>
                      <Radio name=''>随机</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              />
              <Controller
                name='percent'
                control={control}
                render={({ field, fieldState }) => (
                  <Form.Item
                    label='依赖比例'
                    status={getStatus(fieldState)}
                    message={fieldState.error?.message}
                  >
                    <InputNumber
                      {...field}
                      max={1}
                      min={0}
                      style={{ width: '100%' }}
                      size='l'
                      step={0.01}
                      precision={5}
                    />
                  </Form.Item>
                )}
              />
              <Controller
                name='null_value_str'
                control={control}
                render={({ field, fieldState }) => (
                  <Form.Item label='空值标识'>
                    <InputAdornment
                      before={
                        <Select
                          options={['null', '自定义'].map(value => ({
                            value
                          }))}
                          value={field.value === null ? 'null' : '自定义'}
                          onChange={value => {
                            field.onChange(value === 'null' ? null : '')
                          }}
                        />
                      }
                    >
                      {field.value !== null && (
                        <Input {...field} placeholder='不填时为空字符' />
                      )}
                    </InputAdornment>
                  </Form.Item>
                )}
              />
              {sourceData?.nodeType === 'Mock' && (
                <Controller
                  name='depend_table'
                  control={control}
                  render={({ field, fieldState }) => (
                    <Form.Item label='依赖表'>
                      <Form.Text>{field.value}</Form.Text>
                    </Form.Item>
                  )}
                />
              )}

              <Form.Item label='依赖字段'>
                <Table
                  records={records}
                  recordKey='id'
                  columns={[
                    {
                      key: 'depend_table_cols',
                      width: 170,
                      header: sourceData?.name,
                      render: (x, key, index) => (
                        <Select
                          value={x.depend_table_cols}
                          onChange={value => {
                            handleSave(
                              {
                                ...x,
                                depend_table_cols: value
                              },
                              index
                            )
                          }}
                          options={sourceData?.columns.map(col => ({
                            value: col.col_name
                          }))}
                        ></Select>
                      )
                    },
                    {
                      key: 'table_cols',
                      width: 170,
                      header: targetData?.name,
                      render: (x, key, index) => (
                        <Select
                          value={x.table_cols}
                          onChange={value => {
                            handleSave(
                              {
                                ...x,
                                table_cols: value
                              },
                              index
                            )
                          }}
                          options={targetData?.columns.map(col => ({
                            value: col.col_name
                          }))}
                        ></Select>
                      )
                    }
                  ]}
                  bottomTip={
                    <Button
                      style={{ marginLeft: '18px' }}
                      htmlType='button'
                      icon='plus'
                      size='l'
                      onClick={() => setRecords([...records, { id: getId() }])}
                    ></Button>
                  }
                  addons={[
                    removeable({
                      onRemove: (key, context) => {
                        const recordsTmep = records.filter(
                          item => `${item.id}` !== key
                        )
                        setRecords(recordsTmep)
                      }
                    }),
                    scrollable({
                      maxHeight: 340
                    })
                  ]}
                ></Table>
              </Form.Item>
            </Form>
            <Form.Action>
              <Button
                type='primary'
                htmlType='submit'
                loading={formState.isSubmitting}
              >
                保存
              </Button>
              <Button
                type='weak'
                htmlType='button'
                onClick={() => setVisible(false)}
              >
                取消
              </Button>
              <Button
                type='error'
                htmlType='button'
                onClick={async () => {
                  const yes = await Modal.confirm({
                    message: '确认删除当前所选实例？',
                    description:
                      '删除后，该实例下的所有配置将会被清空，且无法恢复。',
                    okText: '删除',
                    cancelText: '取消'
                  })
                  if (yes) {
                    onDelete && onDelete(edgeInfo.edgeId)
                    setVisible(false)
                  }
                }}
              >
                删除
              </Button>
            </Form.Action>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  )
})
