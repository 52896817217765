import React from 'react'
import { getBezierPath, getEdgeCenter, getMarkerEnd } from 'react-flow-renderer'
import { Button, Modal } from 'tea-component'
import './edge.scss'

const foreignObjectSize = 40

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data
}) {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY
  })

  return (
    <>
      <path
        id={id}
        style={style}
        className='react-flow__edge-path'
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className='edgebutton-foreignobject'
      >
        <body>
          <button
            className='edgebutton'
            onClick={async e => {
              e.stopPropagation()
              if (!data.onDelete) return
              const yes = await Modal.confirm({
                message: '确认删除当前所选连接？',
                okText: '删除',
                cancelText: '取消'
              })
              yes && data.onDelete(id)
            }}
          >
            ×
          </button>
        </body>
      </foreignObject>
    </>
  )
}
